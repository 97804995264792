import React from 'react';
import { connect } from 'react-redux';

const Home = props => (
  <div>
    <h1 className="text-center">WELCOME DMS PROJECT</h1>
  </div>
);

export default connect()(Home);
