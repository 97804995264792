import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actionCreators } from '../../store/Distributors/Index';
import { withTranslation } from 'react-i18next';
import MyAutoComplete from '../../components/Shares/My/MyAutoComplete';

class DistributorsIndex extends Component {
    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        this.props.requestDistributorsList("", 1, 100, "");
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <h1>{t('distributors.title')}</h1>
                <div className="my-3">
                    <MyAutoComplete url="/api/distributor" width="400" />
                </div>
                {renderDistributtorsTable(this.props)}
                {renderPagination(this.props)}
            </div>
        );
    }
}

function renderDistributtorsTable(props) {
    return (
        <table className='table table-striped'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Tên</th>
                    <th>Địa Chỉ</th>
                    <th>Email</th>
                </tr>
            </thead>
            <tbody>
                {props.list.map(model =>
                    <tr key={model.Id}>
                        <td>{model.Id}</td>
                        <td>{model.Name}</td>
                        <td>{model.Address}</td>
                        <td>{model.Email}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

function renderPagination(props) {
    const prevPageIndex = (props.currentPage || 0) - 5;
    const nextPageIndex = (props.currentPage || 0) + 5;

    return <p className='clearfix text-center'>
        <Link className='btn btn-default pull-left' to={`/distributors/${prevPageIndex}`}>Previous</Link>
        <Link className='btn btn-default pull-right' to={`/distributors/${nextPageIndex}`}>Next</Link>
        {props.isLoading ? <span>Loading...</span> : []}
    </p>;
}

export default withTranslation()(connect(
    state => state.distributors,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(DistributorsIndex));
