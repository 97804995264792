import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export default axios.create({
    baseURL: baseUrl,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
        'crossDomain': true,
        'X-API-KEY': process.env.REACT_APP_X_API_KEY,
    }
});
