import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from '../Shares/NavMenu/NavMenu';
import { connect } from 'react-redux';

const Layout = props => (
  <div>
    <NavMenu />
    <Container fluid={true}>
      {props.children}
    </Container>
  </div>
);

export default connect()(Layout);
