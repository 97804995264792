import React, { Component } from 'react';
import { Route } from 'react-router';
import Layout from './components/Shares/Layout';
import HomeIndex from './components/Home/Index';
import SitesIndex from './components/Sites/Index';
import DistributorsIndex from './components/Distributors/Index';

export default class App extends Component {

    render() {
        return (
            <Layout>
                <Route exact path='/' component={HomeIndex} />
                <Route path='/sites' component={SitesIndex} />
                <Route path='/distributors' component={DistributorsIndex} />
            </Layout>
        );
    }
};
