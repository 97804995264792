import api from "../../services/api";

const requestDistributorsListType = 'REQUEST_DISTRIBUTOR_LIST';
const receiveDistributorListType = 'RECEIVE_DISTRIBUTOR_LIST';
const initialState = {
        list: [],
        currentPage: 0,
        isLoading: false,
    };

export const actionCreators = {
    requestDistributorsList: (query, currentPage, pageSize, sortOrder) => async (dispatch, getState) => {
        if (currentPage === getState().distributors.currentPage) {
        return;
    }

    dispatch({ type: requestDistributorsListType, currentPage });

    const url = '/api/distributor';

    const payload = {
        query: query,
        pageNumber: currentPage,
        pageSize: pageSize,
        sortOrder: sortOrder
    };

    try {
        const response = await api.get(url, { params: payload });
        dispatch({ type: receiveDistributorListType, currentPage, list: response.data });
    } catch (e) {
        console.log(e);
    }
  }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestDistributorsListType) {
        return {
            ...state,
            currentPage: action.currentPage,
            list: [],
            isLoading: true
        };
      }

    if (action.type === receiveDistributorListType) {
    return {
        ...state,
        currentPage: action.currentPage,
        list: action.list,
        isLoading: false,
    };
  }

  return state;
};
