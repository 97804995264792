// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../services/api';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function MyAutoComplete(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
    const pageSize = props.pageSize;
    const url = props.url;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const payload = {
                query: '',
                pageNumber: 1,
                pageSize: pageSize ? parseInt(pageSize) : 5000,
                sortOrder: '',
            };

            let listData = [];
            try {
                const response = await api.get(url, { params: payload });
                await sleep(1e3);
                listData = response.data
            } catch (e) {
                console.log(e);
            }

            if (active && listData) {
                setOptions(listData);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading, pageSize, url]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-autocomplete"
            style={{ width: props.width ? parseInt(props.width) : 300 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};
